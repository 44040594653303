
@import "./variables.scss";

.bottom-bar{
  
  
a{
   


/* Media Query for low resolution  Phone */
@media (min-width: $breakpoint-phone) and (max-width: $breakpoint-phone-max-width) {
  //margin-left: 50%;
  padding:10px  ; 
    margin-left:auto
  
 

}

/* Media Query for low resolution  Tablets, Ipads portrait*/
@media (min-width: $breakpoint-min-width-tablet-portrait) and (max-width: $breakpoint-max-width-tablet-portrait) {
    //margin-left: 70px;
    padding:10px  ; 
    margin-left:auto
    
}


/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: $breakpoint-min-width-tablet) and (max-width: $breakpoint-max-width-tablet) {
    display: block; 
    padding:10px  ; 
    margin-left:auto
   
    
}


  
}}

.settings-page{
   
     /* Media Query for low resolution  Phone */
@media (min-width: $breakpoint-phone) and (max-width: $breakpoint-phone-max-width) {
    
    margin-left: 50%;
    max-width:320;
    
}


/* Media Query for high resolution  Tablets, Ipads portrait*/
@media (min-width: $breakpoint-min-width-tablet-portrait) and (max-width: $breakpoint-max-width-tablet-portrait) {
    
    max-width:500px;
    margin-left: 30em;
}


/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: $breakpoint-min-width-tablet) and (max-width: $breakpoint-max-width-tablet) {
   //margin-left: -50px; 
    max-width:250px;
}


}